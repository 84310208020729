import {
  Component, Input, ViewChild, ElementRef, Output,
  EventEmitter, ChangeDetectionStrategy
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-advanced-dropdown',
  templateUrl: './advanced-dropdown.component.html',
  styleUrls: ['./advanced-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedDropdownComponent {

  @ViewChild('dropdownContainer', { static: false }) dropdownContainer: ElementRef | undefined;
  @ViewChild('toggleButton') toggleButton: ElementRef | undefined;
  @ViewChild('items') items: ElementRef | undefined;

  @Input() selectSingle: boolean = false;
  @Input() displayKey: string = 'name';
  @Input() subArrayKey: string | undefined;
  @Input() options: any[];
  @Input() selectedOption: any[] | undefined;
  @Input() position: string = 'bottom';
  @Input() disabled: boolean = false;
  @Input() placeholder: string = "";
  @Input() config: AdvancedDropdownConfig | undefined;

  @Output() selectedItem: EventEmitter<any> = new EventEmitter();
  @Output() selectedItemData: EventEmitter<any> = new EventEmitter();
  @Output() searchTerm: EventEmitter<any> = new EventEmitter();

  public isOpen = false;
  public optionsData: any[] = [];
  public selectedPills: any[] = [];
  public selectedIds: number[] = [];
  public breadCrumbValues: any[] = [];
  public term = new FormControl('');

  constructor() {}

  ngOnInit() {
    if (this.config?.apiSearch) {
      this.term.valueChanges.pipe(
        debounceTime(this.config?.debounceTime ?? 0),
      ).subscribe((data: any) => {
        if (data) {
          this.searchTerm.emit(data);
        } else {
          this.optionsData = this.options!;
        }
      });
    }
    else {
      this.term.valueChanges.subscribe((data: any) => {
        if (data) {
          this.optionsData = [];
            this.options!.forEach(item => {
              this.hasValue(item) && this.optionsData.push(item)
            })
        } else {
          this.optionsData = this.options!;
        }
      });
    }
  }

  ngOnChanges() {
    if (this.config?.apiSearch) {
      this.optionsData = this.options!;
      this.selectedPills = this.selectedOption || [];
      this.selectedIds = this.selectedOption?.map((x: any) => x.id) || [];
    }
    else {
      this.optionsData = this.options!;
      this.selectedPills = [];
      this.selectedIds = [];
      if (this.selectedOption?.length) {
        this.getSelectedData(this.selectedOption)
      } else {
        this.selectedPills = [];
        this.selectedIds = [];
      }
    }
  }

  getSelectedData(value: any) {
    this.options!.forEach(item => {
      this.recursiveSelected(item, value.map(function (x: any) {
        return x;
      }));
    })
  }

  recursiveSelected(item: any, value: any) {
    if (item.id && value.includes(item.id)) {
      this.onSelect(item)
    }
    if (item[this.subArrayKey!]?.length) {
      item[this.subArrayKey!].forEach((child: any) => {
        this.recursiveSelected(child, value)
      })
    }
  }

  hasValue(item: any) {
    let valueToReturn = false;
    if (item[this.displayKey].toLowerCase().includes(this.term?.value?.toLowerCase())) {
      valueToReturn = true;
    }
    item[this.subArrayKey!]?.length && item[this.subArrayKey!].forEach((child: any) => {
      if (this.hasValue(child)) {
        valueToReturn = true
      }
    })
    return valueToReturn
  }

  toggleDropdown(event: Event) {
    if (!this.disabled) {
      this.isOpen = !this.isOpen;
      let selector = this.dropdownContainer!.nativeElement.querySelector('.dropdown-open');
      if (this.position == 'bottom') {
        selector.style.bottom = 'auto';
        selector.style.top = '100%';
      } else {
        selector.style.bottom = '100%';
        selector.style.top = 'auto';
      }
    }
  }

  onSelect(data: any) {
    if (this.selectSingle) {
      this.selectedPills = [];
      this.selectedIds = [];
      this.selectedPills.push(data);
      this.selectedIds.push(data.id);
    }
    else if (this.selectedPills.indexOf(data) < 0) {
      data.selected = true;
      this.selectedPills.push(data);
      this.selectedIds.push(data.id);
    } else {
      if (!data.selected) {
        this.removeItem(data);
      }
    }

    this.selectedItem.emit(this.selectedIds);
    this.selectedItemData.emit(this.selectedPills);
  }

  removeItem(data: any) {
    this.selectedPills.splice(this.selectedPills.indexOf(data), 1);
    this.selectedIds.splice(this.selectedIds.indexOf(data.id), 1);
    this.selectedItem.emit(this.selectedIds);
    this.selectedItemData.emit(this.selectedPills);
  }

  subItemClicked(data: any) {
    this.isOpen = true;
    data[this.subArrayKey!]?.length && this.breadCrumbValues.push(data)
    data[this.subArrayKey!]?.length && (this.optionsData = data[this.subArrayKey!])
  }

  changeTo(data: any) {
    if (this.subArrayKey) {
      this.optionsData = data[this.subArrayKey]
      this.breadCrumbValues.splice(this.breadCrumbValues.indexOf(data) + 1, this.breadCrumbValues.length - this.breadCrumbValues.indexOf(data))
    }
  }

  clearOptions() {
    if (JSON.stringify(this.selectedPills) == JSON.stringify(this.options)) {
      this.selectedPills = [];
      this.selectedIds = [];
      this.selectedItem.emit(this.selectedIds);
      this.selectedItemData.emit(this.selectedPills);  
    }
    else {
      this.selectedPills = this.options;
      this.selectedIds = this.options.map((item: any) => item.id);
      this.selectedItem.emit(this.selectedIds);
      this.selectedItemData.emit(this.selectedPills);
    
    }
    // this.optionsData = this.options!;
    // this.breadCrumbValues = [];
  }

  clickOutside(): void {
    this.isOpen = false;
  }

}

export class AdvancedDropdownConfig{
  isSearchable?: boolean;
  apiSearch?: boolean = true; // search from api
  debounceTime?: number = 300;
}